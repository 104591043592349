
<template>
  <div class="Fince_Cw">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">工资单</el-menu-item>
        <el-menu-item index="2">账单核对</el-menu-item>
        <el-menu-item index="3">资金管理</el-menu-item>
        <el-menu-item index="4">发票管理</el-menu-item>
      </el-menu>
      <div class="Men_1"> 说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div>
    </div>
    <div >
      <div class="Tables" style="padding-bottom:21px;display:flex">
        <div class="Trys">
          <div class="uiiu">工资月份：</div>
          <el-date-picker v-model="Months" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
        </div>
        <div class="Trys Tiii" style="margin-right:28px">
          <div class="uiiu" style="margin: 12px 0 0 26px;">工资额：</div>
          <div style="display:flex" class="Topo">
            <el-input v-model="Mooo1" placeholder="￥  输入具体值"></el-input>
            <span style="margin:7px 10px">至</span>
            <el-input v-model="Mooo2" placeholder="￥  输入具体值"></el-input>
          </div>
        </div>
        <div class="Trys" style="display:flex">
          <div class="uiiu" style="width: 98px;">员工：</div>
            <el-select v-model="StaffList" placeholder="请选择" style="margin-right: 15px;">
              <el-option
                v-for="item in StaFFList"
                :key="item.id"
                :label="item.realname"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-input v-model="name" disabled></el-input>
        </div>
        <div class="Trys" style="margin-left:47px;float:right;margin-top:4px">
          <el-button size="small" type="primary" @click="Btn_chaxun">查询</el-button>
          <el-button size="small" @click="Btn_chongzhi">重置</el-button>
        </div>
      </div>
      <div class="Tables">
        <!-- <div class="Tab_T" @click="Add_kq(1)" v-if="TextMoMo('finance:salary:add')"><img class="Img_ti" src="../../assets/img/+++.png" alt="">创建</div> -->
        <div class="Tab_T Botton_lan" @click="Add_kq(1)" v-if="TextMoMo('finance:salary:add')"><img class="Img_ti" src="../../assets/img/+++.png" alt="">创建</div>
        <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
        <div class="dsds">
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>
        <div v-if="TextMoMo('finance:bill:index')">
          <el-table
            :data="tableData"
            class="table"
            ref="multipleTable"
            
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="55 "
              align="center"></el-table-column>
            <el-table-column
              prop="id"
              label="ID"
              width="55"
              align="center"></el-table-column>
            <el-table-column align="center" prop="staff.realname" label="员工" width="140"></el-table-column>
            <el-table-column align="center" prop="month" label="发放月" width="170"></el-table-column>
            <el-table-column align="center" prop="go_clock_num" label="本月出勤(天)" width="140"></el-table-column>
            <el-table-column align="center" prop="total_salary" label="应发工资" width="140"></el-table-column>
            <el-table-column align="center" prop="real_salary" label="实发工资" width="140"></el-table-column>
            <el-table-column align="center" prop="createtime" label="创建时间" width="180"></el-table-column>
            <el-table-column align="center" prop="back_status_text" label="员工反馈" width="140"></el-table-column>
            <el-table-column align="center" label="操作" >
              <template #default="scope">
                <div class="el_butto">
                    <el-button type="text" v-if="TextMoMo('finance:salary:read')" @click="handEdit(2,scope.row)">查看</el-button>
                    <el-button type="text" v-if="!TextMoMo('finance:salary:read')">查看</el-button>
                </div>
                <div class="el_butto" style="margin:0 13px">
                    <el-button
                    type="text" v-if="TextMoMo('finance:salary:edit')"
                    @click="handEdit(1,scope.row)"
                    >编辑</el-button>
                </div>
                <div class="el_butto"  v-if="TextMoMo('finance:salary:confirm')">
                    <el-button
                    v-if="scope.row.back_status == 10"
                    type="text"
                    @click="handSub(scope.row)"
                    >确认</el-button>
                    <el-button v-if="scope.row.back_status != 10"
                    type="text"
                    >已确认</el-button>
                </div>

                <div class="el_butto rere">
                    <el-button
                    type="text" v-if="TextMoMo('finance:salary:del')"
                    class="red"
                    @click="handDelete(scope.row)"
                    >删除</el-button>
                </div>

              </template>
            </el-table-column>
          </el-table>
          <div class=""></div>
          <div class="block">
            <el-pagination
              background
              @current-change="handChange"
              :current-page="currentPage1"
              :page-size="page.pageSize"
              :default-page-size=7
              layout="total, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>


    </div>
    
    <!--添加员工-->
    <div ref="rightPanel" v-if="show1" class="rightPanel-container show">
          <div class="rightPanel-background" />
          <div style="height:1100px;width: 839px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height:1100px">
                <div class="Rig_1" v-if="XX == 'xin'">创建<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_1" v-if="XX == 'bj'">编辑<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_A">
                <div class="xinnnad">基本信息</div>
                <div class="Diidd_Yi">
                    <div class="yiiiuyt">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"> 
                              <span style="color:#FF4D4F">* </span>员工</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj1" placeholder="请选择员工" @focus="Foccewe1" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="yiiiuyt">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 部门</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj2" placeholder="请选择部门" @focus="Foccewe2" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="yiiiuyt">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 职务</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj3" placeholder="请选择员工" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                     <div class="yiiiuyt">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 工资月份</div>
                             <el-date-picker v-model="People_jj4" type="month" placeholder="选择月" style="width:94%"></el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="xinnnad">工资信息</div>
                <div class="Diidd_Yi">
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput" style="margin-bottom:1px">
                          <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 应出勤天数</div>
                          <el-input v-model="People_jj_y_day" placeholder="请输入应出勤天数" class="El_ii" style="width:94%"></el-input>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                        <div class="Rig_D Rl_nput" style="margin-bottom:1px">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 实际出勤天数</div>
                            <el-input v-model="People_jj_s_day" placeholder="请输入实际出勤天数" class="El_ii" style="width:94%"></el-input>
                        </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                        <div class="Rig_D Rl_nput" style="margin-bottom:1px">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 基本工资</div>
                            <el-input v-model="People_jj_s_jb" placeholder="请输入基本工资" class="El_ii" style="width:94%"></el-input>
                        </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">综合补贴</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j1" placeholder="请填写综合补贴" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">绩效工资</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j2" placeholder="请填写绩效工资" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">总提成</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j3" placeholder="请填写总提成" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">当月实发提成</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j4" placeholder="请填写当月实发提成" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">剩余提成</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j5" placeholder="请填写剩余提成" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">全勤奖</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j6" placeholder="请填写全勤奖" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">奖金</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j7" placeholder="请填写奖金" class="El_ii" ></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">考勤扣除</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j8" placeholder="请填写考勤扣除" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">公积金扣除</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j9" placeholder="请填写公积金扣除" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">社保代扣</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j10" placeholder="请填写社保代扣" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">提成扣除</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j11" placeholder="请填写提成扣除" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                  <div class="yiiiuyt">
                      <div class="Rig_D Rl_nput">
                          <div class="Rig_Ddd" style="margin-bottom:9px">所得税扣除</div>
                          <div class="Rtty">
                          <el-input v-model="People_jj_s_j12" placeholder="请填写所得税扣除" class="El_ii"></el-input>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="BCSZ" v-if="XX == 'xin'"  @click="Staff_Add(1)" style="margin-right:24px;margin-left:15px">创建</div>
                <div class="BCSZ" v-if="XX == 'bj'"  @click="Staff_Add(2)" style="margin-right:24px;margin-left:15px">完成</div>
                <div class="BCSZ" @click="show1 = false"  style="background:#fff;color:#595959;border: 1px solid #D9D9D9;cursor: pointer;">取消</div>
              </div>
            </div>
          </el-scrollbar>
        </div>
    </div>
    <!--添加员工-->

    <!--添加员工-->
    <div ref="rightPanel" v-if="show11" class="rightPanel-container show">
          <div class="rightPanel-background" />
          <div style="height:1100px;width: 839px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height:1100px">
                <div class="Rig_1">查看<i class="el-icon-close" @click="show11 = false" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_A">
                <div class="xinnnad">基本信息</div>
                <div style="overflow:hidden">
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"> 
                              <span style="color:#FF4D4F">* </span>员工</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj1" placeholder="请选择员工" @focus="Foccewe1" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 部门</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj2" placeholder="请选择部门" @focus="Foccewe2" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 职务</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj3" disabled placeholder="请选择员工" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 工资月份</div>
                             <el-date-picker v-model="People_jj4" type="month" placeholder="选择月" disabled style="width:94%"></el-date-picker>
                        </div>
                    </div>
                </div>  
                <div class="xinnnad">工资信息</div>
                <div style="overflow:hidden">
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput" style="margin-bottom:1px">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 应出勤天数</div>
                            <el-input v-model="People_jj_y_day" placeholder="请输入应出勤天数" class="El_ii" disabled style="width:94%"></el-input>
                        </div>
                    </div>
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                          <div class="Rig_D Rl_nput" style="margin-bottom:1px">
                              <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 实际出勤天数</div>
                              <el-input v-model="People_jj_s_day" placeholder="请输入实际出勤天数" class="El_ii" disabled style="width:94%"></el-input>
                          </div>
                        </div>
                    </div>
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                          <div class="Rig_D Rl_nput" style="margin-bottom:1px">
                              <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 基本工资</div>
                              <el-input v-model="People_jj_s_jb" placeholder="请输入基本工资" class="El_ii" disabled style="width:94%"></el-input>
                          </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">综合补贴</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j1" placeholder="请填写综合补贴" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">绩效工资</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j2" placeholder="请填写绩效工资" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">总提成</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j3" placeholder="请填写总提成" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">当月实发提成</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j4" placeholder="请填写当月实发提成" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">剩余提成</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j5" placeholder="请填写剩余提成" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">全勤奖</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j6" placeholder="请填写全勤奖" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">奖金</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j7" placeholder="请填写奖金" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                   <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">考勤扣除</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j8" placeholder="请填写考勤扣除" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                   <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">公积金扣除</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j9" placeholder="请填写公积金扣除" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">社保代扣</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j10" placeholder="请填写社保代扣" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                   <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">提成扣除</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j11" placeholder="请填写提成扣除" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                   <div style="width:33%;float:left">
                        <div class="Rig_D Rl_nput">
                            <div class="Rig_Ddd" style="margin-bottom:9px">所得税扣除</div>
                            <div class="Rtty">
                            <el-input v-model="People_jj_s_j12" placeholder="请填写所得税扣除" class="El_ii" disabled></el-input>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="BCSZ" @click="show11 = false"  style="background:#fff;color:#595959;border: 1px solid #D9D9D9;cursor: pointer;">取消</div>

                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>
    <!--添加员工-->

    <!--选择员工  单选-->
    <el-dialog title="选择员工" v-model="editVisible" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <div>
              <el-checkbox-group v-model="checkList2" @change="bindCheckBox">
                <div class="Blo_b" v-for="item in StaffList" :key="item.id">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Chan_Tonx.length != 0">已选：1个员工</div>
          <div v-if="Chan_Tonx.length == 0">已选：0个员工</div>
          <div class="tyhh" v-if="Chan_Tonx.length != 0">
            <img :src="Chan_Tonx.full_avatar" alt="">
            <span>{{Chan_Tonx.realname}}</span>
            <i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <el-button type="primary" @click="Close_4" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择员工-->

    <!--选择部门-->
    <el-dialog title="选择部门" v-model="editVisible1" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <el-tree
              :data="DMent"
              node-key="id"
              ref="tree"
              :check-strictly = "true"
              show-checkbox
              :default-expand-all="false"
              :expand-on-click-node="false"
              @check-change="handleClick"
               @node-click="nodeClick"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span>{{ node.data.name }}</span>
                </span>
              </template>

            </el-tree>

            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Bumen_Tonx != null">已选：1个部门</div>
          <div v-if="Bumen_Tonx == null">已选：0个部门</div>
          <div class="tyhh" v-if="Bumen_Tonx != null">
            <span>{{Bumen_Tonx.name}}</span>
            <i class="el-icon-close" @click="Close_Bumen" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>

      </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <el-button type="primary" @click="BTN_Bumen" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 

    </el-dialog>
    <!--选择部门-->

    <!-- 薪资确认 -->
    <el-dialog title="薪资确认" v-model="editVisible2" width="30%">
        <div>
          <div class="Rig_Ddd" style="margin-bottom:8px;width:80px"> 
            <span style="color:#FF4D4F">* </span>确认选项
          </div>
          <el-radio v-model="radio" label="20">无问题（默认）</el-radio>
          <el-radio v-model="radio" label="30">有异议</el-radio>
        </div>

        <div style="margin: 15px 0 ;"><span style="color:#FF4D4F">* </span>问题描述：（选填）</div>
        <div class="Ryyy">
          <el-input type="textarea" placeholder="您可以将问题详细的描述在这里，财务人员会及时和您沟通" v-model="ED_textarea">
          </el-input>
        </div>

      <div class="Rtty" style="overflow:hidden">
        <div class="BCSZ" @click="Btn_tj">提交</div>
        <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:20px" @click="editVisible2 = false">取消</div>
      </div>
    </el-dialog>
    <!-- 薪资确认 -->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import { fetchData } from "../../api/index";
export default {
  name: "basetable",
  data() {
    return {
      name: localStorage.getItem("ms_username"),
      activeIndex: "1",
      radio:'20',
      ionini:'',
      staff_id:0,
      ED_textarea:'',

      Months:'',
      Mooo1:'',
      Mooo2:'',
      StaffList:'',

      StaFFList:'',

      total:0,
      currentPage1:0,
      input_seach:'',
      Bumen_Xinj1:null,

      People_jj1:'',
      People_jj2:'',
      People_jj3:'',
      People_jj4:'',

      People_jj_y_day:'',
      People_jj_s_day:'',
      People_jj_s_jb:'',

      People_jj_s_j1:'',
      People_jj_s_j2:'',
      People_jj_s_j3:'',
      People_jj_s_j4:'',
      People_jj_s_j5:'',
      People_jj_s_j6:'',
      People_jj_s_j7:'',
      People_jj_s_j8:'',
      People_jj_s_j9:'',
      People_jj_s_j10:'',
      People_jj_s_j11:'',
      People_jj_s_j12:'',

      
      DepartmentList:[],
      StaffList:[],
      Parents:[],

      show1: false,
      show11: false,
      show2: false,

      XX:'xin',
      Bumen_Tonx:[],
      checkList2:[],
      DMent:[],
      department_id:0,

      editVisible:false,
      editVisible1:false,
      editVisible2:false,
      input: "",
      query: {
          address: "",
          name: "",
          pageIndex: 1,
          pageSize: 10
      },
      
      tableData: [],
      multipleSelection: [],

      Chan_Tonx: [],
      Chan_TARR: [],
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
      Row:{}
    };
  },
  mounted() {
    var that = this
    that.AAAAA()
    that.StaFFLis()
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    Close() {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.dialogVisible6 = false;
      this.dialogVisible7 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    },
    Add_kq(e) {  // 创建
        var that = this
        if (e == 1) {
          that.show1 = true
          that.XX = 'xin'
          that.People_jj1 = '',
          that.People_jj2 = '',
          that.People_jj3 = '',
          that.People_jj4 = '',

          that.People_jj_y_day = '',
          that.People_jj_s_day = '',
          that.People_jj_s_jb = '',

          that.People_jj_s_j1 = '',
          that.People_jj_s_j2 = '',
          that.People_jj_s_j3 = '',
          that.People_jj_s_j4 = '',
          that.People_jj_s_j5 = '',
          that.People_jj_s_j6 = '',
          that.People_jj_s_j7 = '',
          that.People_jj_s_j8 = '',
          that.People_jj_s_j9 = '',
          that.People_jj_s_j10 = '',
          that.People_jj_s_j11 = '',
          that.People_jj_s_j12 = ''
        }
    },
    handleSelect(key, keyPath) {
      var that = this
      if (key == 1) {
        that.$router.push('./Fince_cw')
      } else if(key == 2){
        that.$router.push('./Fince_cw_B')
      } else if (key == 3) {
        that.$router.push('./Fince_cw_C')
      } else if (key == 4) {
        that.$router.push('./Fince_cw_D')
      }
     },
    handleSelect1(key, keyPath) {
      if (key == 1) {
        this.Index1 = true
        this.Index2 = false
      } else {
        this.Index1 = false
        this.Index2 = true
      }
    },
    inpu_sech() {   // 搜索材料
        var that = this
        axios({ 
          method: 'post',
          url: '/admin/finance/salary/index',    
          data: {
            page: 1,
            limit:7,
            filter:{
                keyword:that.input_seach
              }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.total = res.data.data.total
                that.tableData = res.data.data.rows
            }

        })
    }, 
    getData() {
        fetchData(this.query).then(res => {
            console.log(res);
            this.tableData = res.list;
            this.pageTotal = res.pageTotal || 50;
        });
    },
    // 触发搜索按钮
    handleSearch() {
        this.$set(this.query, "pageIndex", 1);
        this.getData();
    },
    handSub(e) {
      var that = this
      console.log(e)
      that.editVisible2 = true
      that.staff_id = e.id
    },
    handDelete(e) {       // 删除工资
        var that = this
        this.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/finance/salary/del',
            data: {
              id: e.id
            },
          }).then(function (res) {
            that.AAAAA()
          })                 

                
            })
    },
    // 多选操作
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    Btn_tj() {          //确认有无异议提交
      var that = this
        axios({ 
          method: 'post',
          url: '/admin/finance/salary/confirm',    
          data: {
            id:that.staff_id,
            status:that.radio,
            msg:that.ED_textarea
          },
        }).then(function (res) {
          console.log(res)
          if (res.data.code == 0) {
             that.$message.warning(res.data.msg);
             that.editVisible2 = false
          }else {
             that.$message.success(res.data.msg);
            that.editVisible2 = false
            that.AAAAA()
          }
        })      
    },
    Btn_chongzhi() {
      var that = this
                that.Months = '',
                that.Mooo1 = '',
                that.Mooo2 = ''
                that.StaffList = [],
                that.input_seach = ''
    },

    Btn_chaxun() {        //查询
      var that = this
        var date = new Date(that.Months)
          var y = date.getFullYear()
          var m = date.getMonth() + 1
          m = m < 10 ? ('0' + m) : m
          var d = date.getDate()
          d = d < 10 ? ('0' + d) : d
          const time = y + '-' + m + '-' + d
          // return time
          console.log(time)
          axios({ 
            method: 'post',
            url: '/admin/finance/salary/index',    
            data: {
              page: 1,
              offset:0,
              limit:7,
              filter:{
                time:time,
                money:{
                  min:that.Mooo1,
                  max:that.Mooo2
                },
                staff_id:that.StaffList,
                keyword:that.input_seach
              }
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total = res.data.data.total
                that.tableData = res.data.data.rows
              }

          })
    },
    handChange(val) {     // 分页
      var that = this
      that.currentPage1 = val
        axios({ 
          method: 'post',
          url: '/admin/finance/salary/index',    
          data: {
            page: val,
            limit:7,
            filter:{
                keyword:that.input_seach
              }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.total = res.data.data.total
                that.tableData = res.data.data.rows
            }

        })
    },
    AAAAA() {
          var that = this
          axios({ 
            method: 'post',
            url: '/admin/finance/salary/index',    
            data: {
              page: 1,
              offset:0,
              limit:7,
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total = res.data.data.total
                that.tableData = res.data.data.rows
              }

          })
    },
    StaFFLis() {      //全部员工列表
      var that = this
      axios.get('/admin/ajax/staffList',{   // 通讯录选择
        }).then(function (res) {
          if (res.code == 0) {
            alert('请求失败');  
          } else{ 
            that.StaFFList = res.data.data
          }
      })      
    },
    Staff_Add(e) {        //  确认添加 编辑完成 
      var that = this
      var date = new Date(that.People_jj4)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      // return time
      console.log(time)
      if (e == 1) {
        console.log(that.Chan_Tonx)
        console.log(that.Bumen_Tonx)
        axios({ 
          method: 'post',
          url: '/admin/finance/salary/add',    
          data: {
            row:{
              staff_id:that.Chan_Tonx.id,
              time:time,
              need_clock_num:that.People_jj_y_day,
              go_clock_num:that.People_jj_s_day,
              basic:that.People_jj_s_jb,

              subsidy:that.People_jj_s_j1,
              performance:that.People_jj_s_j2,
              percentage:that.People_jj_s_j3,

              percentage_month:that.People_jj_s_j4,
              percentage_other:that.People_jj_s_j5,
              bonus:that.People_jj_s_j7,

              full_attendence_reward:that.People_jj_s_j6,
              deduct_clock:that.People_jj_s_j8,
              accumulation_fund:that.People_jj_s_j9,

              insurance:that.People_jj_s_j10,
              deduct_percentage:that.People_jj_s_j11,
              income_tax:that.People_jj_s_j12
            }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.show1 = false
                that.$message.success(res.data.msg);
                that.AAAAA()

            }

        })
      }else if(e == 2) {

      }
    },
    handEdit(c, t) {
      var that = this
      var e = []
      that.XX = "bj"
      if (c == 1) {
        that.show1 = true
      } else if (c == 2) {
        that.show11 = true
      }
      axios.get('/admin/finance/salary/read',{   // 模块
        params:{
            id:t.id
        }
        }).then(function (res) {
          if (res.code == 401) {
              that.$router.push('/Login');
          } else{
            e = res.data.data.row
            that.People_jj1 = e.staff.realname,
            that.People_jj2 = e.department.name,
            that.People_jj3 = e.staff.job,
            that.People_jj4 = e.time,

            that.People_jj_y_day = e.need_clock_num,
            that.People_jj_s_day = e.go_clock_num,
            that.People_jj_s_jb = e.basic,

            that.People_jj_s_j1 = e.subsidy,
            that.People_jj_s_j2 = e.performance,
            that.People_jj_s_j3 = e.percentage,

            that.People_jj_s_j4 = e.percentage_month,
            that.People_jj_s_j5 = e.percentage_other,
            that.People_jj_s_j7 = e.bonus,

            that.People_jj_s_j6 = e.full_attendence_reward,
            that.People_jj_s_j8 = e.deduct_clock,
            that.People_jj_s_j9 = e.accumulation_fund,

            that.People_jj_s_j10 = e.insurance,
            that.People_jj_s_j11 = e.deduct_percentage,
            that.People_jj_s_j12 = e.income_tax
          }
      })

    },

    //----------------------------------------------------------SSSSSSSSSSSSSSSSSSS选择人员SSSSSSSSSSSSSSSSSS------------------------------------------
    Foccewe1() {    //员工获取焦点
      var that = this
      that.Chan_Tonx = []
      that.Chan_TARR = []

      that.editVisible = true 
      axios.get('/admin/company/staff/subListFilter',{   // 通讯录选择
          params:{
            id: 0
          }
        }).then(function (res) {
          if (res.code == 0) {
            alert('请求失败');  
          } else{ 
            that.DepartmentList = res.data.data.departmentList
            that.StaffList = res.data.data.staffList
            that.Parents = res.data.data.parents
          }
      })
    },
 
    Bloo_Black(e) {         // 通讯录选择
          var that = this
          axios.get('/admin/company/staff/subListFilter',{   
            params:{
              id: e
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents
            }
        })
    },
    Nice_x(e) {             //通讯录下级
        var that = this
        axios.get('/admin/company/staff/subListFilter',{
            params:{
              id: e
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents
            }
        })
    },
    bindCheckBox(value){     //通讯录选择员工   单选
      var that = this
      if(that.checkList2.length > 1){
          that.checkList2.splice(0,1)
      }
      var arr = that.checkList2.toString()
      var brr = that.StaffList
      console.log(brr)
      for (let i = 0; i < brr.length; i++) {
        if (brr[i].id == arr) {
          that.Chan_Tonx = brr[i]
        }
      }
      if (value == '') {
        that.Chan_Tonx = []
      }
    },
    Close_2() {             //通讯录取消选择
      var that = this
      that.Chan_Tonx = []
      that.editVisible = false;
    },
    Close_4() {         //选择人员确认
      var that = this
      that.People_jj1 = that.Chan_Tonx.realname
      that.People_jj3 = that.Chan_Tonx.job
      that.editVisible = false;
      that.show1 = false
      setTimeout(function(){
        that.show1 = true
      },1)    
    },
    //---------------------------------------------------------EEEEEEEEEEEEEEEEEEEEEE选择人员EEEEEEEEEEEEEEEEE----------------------------------------
    Foccewe2() {
      var that = this
      that.editVisible1 = true 
        axios.get('/admin/company/department/index',{   // 部门列表
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DMent = res.data.data.rows
              that.department_id = res.data.data.rows[0].id
            }
        })
    },
    handleClick(data,checked, node){  // 勾选变更功能部门内
      var that = this
      that.Bumen_Tonx = data
      console.log(data)
      if(checked == true){
        that.checkedId = data.comcode;
        that.$refs.tree.setCheckedNodes([data]);
      }
    },
    nodeClick(data,checked,node){     // 单选
      this.checkedId = data.comcode
      this.$refs.tree.setCheckedNodes([data]);
    },
    Close_Bumen() {  // 删除已选部门
      var that = this
      that.Bumen_Tonx = null
    }, 
    BTN_Bumen() {   //选择部门确认
      var that = this
      that.show1 = false
      that.editVisible1 = false
      that.People_jj2 = that.Bumen_Tonx.name
      
      setTimeout(function(){
        that.show1 = true
      },1)
    },    
  },
};
</script>
